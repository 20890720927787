<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <h2>深圳市博尔姆斯科技有限公司</h2>
      <h3>网站信息安全管理制度</h3>

      <div class="box-con">
       
        <p>
          为了加强网站信息内容安全管理，抑制反动、色情等不良内容的出现，严格遵守国家法律、法规，维护国家安定团结，明确监控成员职责及工作流程，特制定本管理制度。
        </p>
        <h2>&nbsp; &nbsp; 一、组织保障</h2>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;设立信息安全小组，组长由总经理担任。</p>
        <p>具体负责人：公司运营总监、技术总监</p>
        <h2>&nbsp; &nbsp; 二、基本原则</h2>
        <p>1、网站内容实行先审后发制度；</p>
        <p>
          2、监控人员实行7×24小时人员监控制度，白班、午班、夜班轮流值班，不间断地对所有网站内容及过滤审核内容进行实时监控，每一班次工作时间8小时。
        </p>
        <p>
          3、节日期间，做好值班人员的交代工作，同时保证所有机房工作人员及公司相关人员的手机24小时开机，随叫随到。
        </p>
        <h2>&nbsp; &nbsp; 三、启动条件</h2>
        <p>
          本预案启动的条件为：网站遭受攻击黑客、网页被篡改、页面出现违规视听节目、因主机设备或软件系统故障导致的网站无法访问。
        </p>
        <h2>&nbsp; &nbsp; 四、工作要求</h2>
        <p>1、监控人员要有危机意识和政治敏感性，并严格按照工作流程操作；</p>
        <p>2、当班时不得脱岗、聊天等做与监控工作无关的事；</p>
        <p>
          3、下班前做好工作日志，认真填写交接班表，如有特殊原因未记录，事后请写明原因，未做记录者按缺勤处理；
        </p>
        <p>
          4、交接班时接班人员必须到岗后，交班人员方可离开，如有特殊原因需提前向监控主管说明；
        </p>
        <p>
          5、工作中如出现严重误、漏删情况，则按照相关规定给予处罚，对于影响网站安全运营者，将追究相关人员责任。
        </p>
        <h2>&nbsp; &nbsp; 五、应急响应流程</h2>
        <p>（一）遭受黑客攻击时的应急响应流程</p>
        <p>1、工作时间内，发现黑客攻击应在第一时间通知具体责任人。</p>
        <p>
         具体责任人接到通知后，应详细记录有关现象和显示器上出现的信息，将被攻击的服务器等设备从网络中隔离出来，保护现场。同时通知总负责人，召集相关技术人员共同分析攻击现象，提供解决方法，主机系统管理员和应用软件系统管理员负责被攻击或破坏系统的恢复与重建工作。视情况向集团公司领导汇报事件情况。
        </p>
        <p>
          2、非工作时间内发现的攻击事件，值班人员应首先立即切断被攻击外网服务器的网络连接，并做好相关记录；然后通知具体责任人按流程处理。
        </p>
        <p>（二）页面被篡改、出现违规节目的应急响应流程</p>
        <p>1、工作时间内发现视听节目，应在第一时间通知具体责任人。</p>
        <p>具体责任人接到通知后：</p>
        <p>（1）将服务器从网络中隔离，抓屏、保存违规视听节目的页面。</p>
        <p>（2）修复网页内容、删除网站上的违规视听节目。</p>
        <p>
          （3）网页修复后，对网站全部内容进行一次查看，确保没有被篡改的或违规情况后解除站点服务器的隔离。
        </p>
        <p>
          （4）会同技术人员共同追查非法篡改、违规节目来源，尽可能确定信息发布者。
        </p>
        <p>（5）向总负责人报告情况，视情况向公司领导汇报事件情况。</p>
        <p>
          2、非工作时间内发现的篡改事件，值班人员应首先立即通知负责人，请其切断被攻击外网服务器的网络连接，并做好相关记录；然后通知具体责任人按流程处理。
        </p>
        <p>（三）网站无法访问</p>
        <p>发现网站无法访问的情况后，立即通知具体负责人。</p>
        <p>
          具体负责人接到通知后，应及时确定故障原因。如因主机设备或软件系统故障导致且不能在2小时内解决，应及时启动备用网站。
        </p>
        <p><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "博拉琴房";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  padding-top: 60px;
  box-sizing: border-box;
  h2 {
    font-size: 21px;
    color: #333;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin: 10px 30px;
  }

  .box-con {
    font-size: 14px;
    color: #888;
    line-height: 40px;
    h2 {
        text-align: left;
    }
    h3 {
      text-align: left;
      margin: 10px 0;
    }
    p {
      text-align: left;
    }
  }
}
</style>
