<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <h2>深圳市博尔姆斯科技有限公司</h2>
      <h3>网络文化自审制度</h3>

      <div class="box-con">
        <h3>一、目的</h3>
        <p>
          根据《互联网文化管理暂行规定》和《网络文化经营单位内容自审管理办法》，为加强网络文化内容建设与管理，规范网络文化经营单位产品及服务内容自审工作，深圳市博尔姆斯科技有限公司特制定了以下制度，以确保本公司所有网络文化产品的内容及日常运营行为符合办法的规定。本制度旨在规范本公司网络文化产品及服务内容（包括但不限于产品宣传推广、活动策划）的自审工作，本企业按照《网络文化经营单位内容自审管理办法》开展相关工作的职责、标准、流程及责任追究等。
        </p>
        <h3>二、组织架构</h3>
        <p>
          建立有效的组织架构，专门成立以、公司总经理为总负责人的自审管理部门，其中内容自审专员3名，负责拟上线网络文化产品及服务内容的事先审核、相关产品报审报备及运营平台的日常巡查，做到机构到位，责任到岗审查人员职责。
        </p>
        <h3>三、审查人员职责</h3>
        <p>
          1、公司总经理为内容自审管理工作总负责人，负责组织领导企业自审管理工作的开展、自审报告的复核签字，对审核结果承担总责任；
        </p>
        <p>2、掌握内容审核的政策法规和相关知识；</p>
        <p>
          3、在任何情况下，审核人员均可在内容自审这一工作中表达其独立的审核意见；
        </p>
        <p>
          4、参加文化部组织的企业内容自审人员业务培训；审核人员有组织内部培训工作，对企业开发、运营人员进行内容审查方面的培训的责任；
        </p>
        <p>
          5、审核人员必须以保证本企业的产品和服务的合注性和合规性为目的，对在自审工作中涉及违法违规的产品及服务内容进行记录并提请中止，作出提交修改，督促修改，复审等审核意见。重大问题向主管部门报告；
        </p>
        <p>6、审核人员负责保存审查记录。</p>
        <h3>四、审核标准</h3>
        <p>在所有需要审核人员进行自审的内筒中，不得含有以下内容：</p>
        <p>（一）违反宪法确定的基本原则的；</p>
        <p>（二）危害国家统一、主权和领土完整的；</p>
        <p>（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</p>
        <p>
          （四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族、风俗、习惯的；
        </p>
        <p>（五）宣扬邪教、迷信的；</p>
        <p>（六）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（七）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；</p>
        <p>（八）侮辱、诽谤他人，侵害他人合法权益的；</p>
        <p>（九）违背社会公德的；</p>
        <p>（十）有法律、行政法规和国家规定禁止的其他内容的。</p>
        <h3>五、审核流程</h3>
        <p>
          1、对本公司自主创作的作品，在创作阶段进行培训，对故事背景、美术素材等进行初步的筛查；
        </p>
        <p>
          2、自审人员在作品发布之前，依据内容审查的相关规定，对产品及其服务内容（包括宣传推广和活动策划）进行审查，对违法违规的内容进行记录，并签发初审意见；
        </p>
        <p>
          3、对初审有问题的作品，退回用户或部门进行修改，并对修改后的内容进行复查；
        </p>
        <p>
          4、对内容的合法合规性不能准确判断的，应向文化主管部门申请行政指导；
        </p>
        <p>5、对复查仍有问题的，应按照上述第2-4项规定的内容重新进行审核；</p>
        <p>
          6、在内容发布前，对作品内容进行审查，审查内容包括证照使用、实名注册、实名信息补填、用户协议必备条款、适龄提示等内容，合格后方可发布作品;
        </p>
        <p>
          7、对审查完成的做品，自审人员提出同意发布的意见，用户签字确认，上交内容审查管理工作的主要负责人；
        </p>
        <p>
          8、内容审查管理工作的主要负责人签字确认后，作品会由网站管理员发布到网站中。
        </p>
        <p>
          9、日常对作品和服务内容进行监督检查，包括作品内容、宣传推广和活动策划等各个方面和环节，发现问题提交检查意见，报本公司内容审查管理工作主要负责人；
        </p>
        <p>10、自审人员的所有审查意见应归档留存，保存时间不少于两年。</p>
        <h3>六、内部培训</h3>
        <p>
          1、为确保及时准确的了解国家最新有关政策法规，公司定期对内部人员进行有关法规的讲解和培训，并进行考试。覆盖对象主要是审核人员、产品策划、美术及各部门负责人。
        </p>
        <p>
          2、培训不定期举行，主要是针对最新出台的法规政策，以及新近升职上任的管理人员所进行。培训基本内容包括《互联网文化管理暂行规定》、《网络文化经营许可证》有关学习材料。
        </p>
        <h3>七、应急管理</h3>
        <p>1、设立自审信息安全小组，组长由总经理担任。</p>
        <p>
          2、信息安全小组负责对公司所有网络资源进行实时监控，做到及时发现、即时处理的原则办理，并对处理结果备案，对重大有害信息事件，应在第一时间上报主管领导及相关部门。
        </p>
        <p>
          3、信息安全小组负责受理关于内容安全和信息安全的举报投诉，负责界定、监控、受理有害信息事件，要做到即接快办；夜间、节假日值班期间接到、发现的，应于次日或节假日后的第一个工作日办理，对需紧急办理的重大信息安全事件可先处理后登记（如遇紧急情况，可直接关闭服务器等设备，暂停网络运行）。
        </p>
        <p>
          4、负责查办的相关人员接到交办的事件后，应及时安排办理，要求在最短时限内处理完毕，如遇特殊情况不能按时处理完毕的，应报主管领导说明情况，可适当延长处理时间，处理结果应及时反馈给信息安全小组组长。在处理有害信息事件时，应按照处理流程，及时填写相应表单，并随处理结果报告一并存档。
        </p>
        <p>
          5、处理人员应对重大有害信息时间的举报人、发现人要求保密者做到保密，有关重大的有害信息事件及处理过程不得泄密。
        </p>
        <h3>八、责任追究</h3>
        <p>
          1、对审查员未能在审查中发现问题的，予以警告处置，多次出现相同这一状况，取消其审查员资格；
        </p>
        <p>
          2、对审核员发现问题，但开发人员拒不修改的，予以警告处置，并通报其上级主管；
        </p>
        <p>
          3、对审核员发现问题，但开发人员修改，审查通过后自行予以恢复的，予以严重警告处置，通报其上级主管，并处以罚款；
        </p>
        <p>
          4、对运营人员在运营过程中未通知审查员，造成不良后果，而审查员也未及时发现的，予以涉事双方警告处置，责成改正；
        </p>
        <p>
          5、其他情形造成不良后果的，予以警告处置，并通报其上级主管，保留进一步追责的权利。
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p style="text-align: right">深圳市博尔姆斯科技有限公司</p>
        <p style="text-align: right">2021年7月9日&nbsp; &nbsp;</p>
        <p><br style="white-space: normal" /></p>
        <p style="line-height: 1.5em">
          <span style="font-family: 宋体"></span><br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "博拉琴房";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  padding-top: 60px;
  box-sizing: border-box;
  h2 {
    font-size: 21px;
    color: #333;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin: 10px 30px;
  }

  .box-con {
    font-size: 14px;
    color: #888;
    line-height: 40px;
    h3 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
}
</style>
