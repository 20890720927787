<template>
  <div>
    <!-- <nav-header></nav-header> -->
    <router-view></router-view>
    <nav-footer
      :email="this.email"
      :phone="this.phone"
      :name="this.name"
      :addr="this.addr"
      :icp="this.icp"
    ></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
export default {
  name: "home",
  components: {
    NavFooter,
  },
  data() {
    return {
      email: "网站邮箱：bola210521@163.com",
      phone: "网站名称：博拉琴房",
      icp: "备案号：粤ICP备2021070211",
      name: "深圳市博尔姆斯科技有限公司",
      addr: "网站地址：深圳市龙岗区坂田街道五和社区五和大道118号和成世纪名园三栋B座2001",
    };
  },
};
</script>
<style lang='' scoped>
</style>