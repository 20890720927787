import 'babel-polyfill'
import Vue from 'vue'
import router from './router.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import APlayer from '@moefe/vue-aplayer'
Vue.use(APlayer, {
  defaultCover: 'https://github.com/u3u.png',
  productionTip: true,
});

/* <script src="https://cdn.bootcss.com/babel-polyfill/6.23.0/polyfill.min.js"></script> */
router.afterEach(() => {
  window.scrollTo(0, 0)
})
Vue.use(require('vue-wechat-title'))
Vue.use(ElementUI);

Vue.config.productionTip = false
// 挂载 axios，可以 this 调用
Vue.use(VueAxios, axios)
new Vue({
  router,
  render: h => h(App),
  
}).$mount('#app')
