<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <h2>深圳市博尔姆斯科技有限公司</h2>
      <h3>未成年人保护机制</h3>

      <div class="box-con">
        
        
        <p>
          “网络学习未成年人家长监护工程”是一项由国内网络学习企业共同发起并参与实施，由中华人民共和国文化部指导，旨在加强家长对未成年人参与网络学习的监护，引导未成年人健康、绿色参与网络学习，和谐家庭关系的社会性公益行动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正部分未成年子女沉迷网络的行为成为可能。
        </p>
        <p>
          该项社会公益行动充分反映了中国网络学习行业高度的社会责任感，对未成年玩家合法权益的关注及对用实际行动营造和谐社会的愿望。
        </p>
        <p>
          “家长监护机制”是我公司在这一公益行动中，针对目前未成年人缺乏自控及自律能力，容易陷入沉迷；少数监护人缺少时间照顾未成年人，不能及时监督未成年人游戏时间的现状，而推出的一种可由家长实施监控，纠正部分未成年子女沉迷网络的保护机制。
        </p>
        <h3>《未成年人健康参与网络学习提示》</h3>
        <p>
          随着网络在青少年中的普及，未成年人接触网络学习已经成为普遍现象。为保护未成年人健康参与游戏，在政府进一步加强行业管理的前提下，家长也应当加强监护引导。为此，我们为未成年人参与网络学习提供以下意见：
        </p>
        <p>
          一、主动控制网络娱乐时间。网络娱乐活动只是学习、生活的调剂，要积极参与线下的各类活动，并让父母了解自己在网络学习中的行为和体验。
        </p>
        <p>
          二、不参与可能耗费较多时间的游戏设置。不玩大型角色扮演类游戏，不玩有PK类设置的游戏。在校学生每周玩游戏不超过2小时，每月在游戏中的花费不超过10元。
        </p>
        <p>
          三、不要将游戏当作精神寄托。尤其在现实生活中遇到压力和挫折时，应多与家人朋友交流倾诉，不要只依靠游戏来缓解压力。
        </p>
        <p>
          四、养成积极健康的游戏心态。克服攀比、炫耀、仇恨和报复等心理，避免形成欺凌弱小、抢劫他人等不良网络行为习惯。
        </p>
        <p>
          五、注意保护个人信息。包括个人家庭、朋友身份信息，家庭、学校、单位地址，电话号码等，防范网络陷阱和网络犯罪。
        </p>
        <h3>家长监护服务说明：</h3>
        <p>
          家长监护系统充分考虑家长的实际需求，当家长发现自己的孩子玩游戏过于沉迷的时候，由家长提供合法的监护人资质证明、游戏名称账号、以及家长对于限制强度的愿望等信息，可对处于孩子游戏沉迷状态的账号采取几种限制措施，解决未成年人沉迷网络的不良现象，如限制未成年人每天玩游戏的时间区间和长度，也可以限制只有节假日才可以游戏，或者完全禁止。
        </p>
        <h3>家长监护服务进度查询：</h3>
        <p>
          如果你已经申请家长监护服务，在服务期间，当你对需要提交的信息、处理结果有疑问，或者其他任何问题，你均可以在工作时间联系我们，我们将由专门负责的受理专员为你提供咨询解答服务，或者配合、指导你解决问题。
        </p>
        <p><br /></p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "博拉琴房";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  padding-top: 60px;
  box-sizing: border-box;
  h2 {
    font-size: 21px;
    color: #333;
    text-align: center;
  }
  h3 {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin: 10px 30px;
  }

  .box-con {
    font-size: 14px;
    color: #888;
    line-height: 40px;
    h3 {
      text-align: left;
      margin: 10px 0;
    }
    p {
      text-align: left;
    }
  }
}
</style>
