<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <!-- 轮播图 -->
    <div class="swiper">
      <div class="swiper-box">
        <swiper :options="swiperOption">
          <!-- 循环想 -->
          <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
            <a href="javascript:;"><img :src="item.img" /></a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
    <!-- 定宽布局 -->
    <div class="my-container">
      <!-- 标题 -->
      <div class="bt">
        <!-- 图标 -->
        <div class="bt-icon">
          <i class="iconfont icon-yinle"></i>
        </div>

        <!-- 子标题1 -->
        <h4>有音乐,灵魂便不会寂莫,用美妙的音符,编出灿烂的人生乐章</h4>
        <h2>推荐音乐</h2>
      </div>

      <!-- 歌曲区块1 -->
      <div class="m1-con">
        <!-- 循环项 -->
        <div
          class="m1-list"
          v-for="(item, index) in my_data"
          :key="index"
          @click="goDays(item.methods, item.id)"
        >
          <!-- 上层 -->
          <div class="item-s">
            <div class="item-img">
              {{ item.id }}
            </div>
            <div class="item-title">
              {{ item.title }}

              <p>{{ item.lrc }}</p>

              <p v-if="item.pay" class="item-pay">{{ item.pay }}</p>
            </div>
          </div>

          <div class="m1-footer">
            <div class="item-l">
              <span>{{ item.p1 }}</span>
              <span>{{ item.p2 }}</span>
              <span>{{ item.p3 }}</span>
              <span>作曲： {{ item.name }}</span>
            </div>

            <div class="item-btn">立即播放</div>
          </div>
        </div>
      </div>

      <!-- 分页器 -->
      <!-- <navigation
        :pages="pages"
        :current.sync="pageNo"
        @navpage="msgListView"
      ></navigation> -->

      <!-- 标题 -->
      <div class="bt">
        <!-- 图标 -->
        <div class="bt-icon">
          <i class="iconfont icon-yinle1"></i>
        </div>

        <!-- 子标题1 -->

        <h4>有音乐,灵魂便不会寂莫,用美妙的音符,编出灿烂的人生乐章</h4>
        <h2>关于我们</h2>
      </div>
      <!-- 关于我们区块 -->
      <div class="info-con">
        <img src="/imgs/ct2.jpg" alt="" />
        <div class="info-box">
          <p>
            博尔姆斯（深圳）科技有限公司注册成立于2021年。是一家集音乐制作、线上教育、留学指导、数字传媒、全球夏令营为一体，建立B2B产业链的专业科技公司。公司位于深圳市。Promus（博尔姆斯）取自拉丁文，寓意：“专业管家”；秉承着“专业、深度、探索、服务”的经营理念，致力于打造为用户提供更精准、专业的品牌团队。

          </p>
         
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <p>扫码收费：1元</p>
      </template>
    </modal>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import NavHeader from "./../components/NavHeader";
// import navigation from "./../components/navigation";
// import as from "./../components/a";
import Modal from "./../components/Modal.vue";
// import Ma from "./../components/Ma.vue";

export default {
  name: "index",
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "/",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 6,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      drawer: false,
      direction: "ltr",
      my_lyc: [],
      my_title: "",
      imgUrl: "",
      showModal: false,
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/s1.jpg",
        },
        {
          id: "2",
          img: "/imgs/s2.jpg",
        },
      ],
    };
  },
  components: {
    NavHeader,
    // navigation,
    // as,
    Modal,
    Swiper,
    SwiperSlide,
    // Ma,
    // "a-player": VueAplayer,
  },
  mounted() {
    document.title = "博拉琴房";
    this.init();
  },
  methods: {
    // 获取音乐数据
    init() {
      this.axios.get("/mock/index.json").then((res) => {
        this.my_data = res.data;
      });
    },

    init2() {
      this.axios.get("/mock/index2.json").then((res) => {
        this.my_data = res.data;
      });
    },
    init3() {
      this.axios.get("/mock/index3.json").then((res) => {
        this.my_data = res.data;
      });
    },
    init4() {
      this.axios.get("/mock/index4.json").then((res) => {
        this.my_data = res.data;
      });
    },
    init5() {
      this.axios.get("/mock/index5.json").then((res) => {
        this.my_data = res.data;
      });
    },
    init6() {
      this.axios.get("/mock/index6.json").then((res) => {
        this.my_data = res.data;
      });
    },

    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },

    // 收费逻辑
    gopay() {
      console.log(this.state);

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("https://www.jq22.com/jquery/bootstrap-3.3.4.css");
.index {
  // 轮播图
  .swiper {
    width: 100%;
    height: 500px;
    // background-color: pink;
    margin-bottom: 80px;
  }
  .my-container {
    // 标题
    .bt {
      width: 100%;
      text-align: center;
      margin: 60px auto;
      // 图标
      .bt-icon {
        i {
          font-size: 68px;
          color: $colorZ;
          font-weight: bold;
        }
      }

      h2 {
        font-size: 24px;
        color: 555;
        margin-top: 20px;
      }
      h4 {
        font-size: 18px;
        color: #1e9133;
      }
    }

    // 音乐区块1
    .m1-con {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // background-color: pink;
      // 循环项
      .m1-list {
        position: relative;
        display: inline-block;
        margin-bottom: 24px;
        // width: 350px;
        width: 550px;
        height: 155px;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid transparent;
        box-sizing: border-box;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
        padding: 0 20px;

        // 上层
        .item-s {
          width: 100%;
          display: flex;
          // justify-content: space-around;
          align-items: center;

          // 图片
          .item-img {
            width: 100px;
            height: 100px;
            background-color: $colorZ;
            color: #fff;
            text-align: center;
            line-height: 100px;
            font-size: 30px;
            font-weight: bold;
          }
          // 标题
          .item-title {
            font-size: 16px;
            margin-left: 50px;
            // font-weight: bold;
            p {
              width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 12px;
              margin-top: 15px;
              // -o-text-overflow: ellipsis;
            }
            .item-pay {
              color: #1e9133;
              font-weight: bold;
              cursor: pointer;
              font-size: 14px;
            }
          }
        }

        // 下层
        .m1-footer {
          width: 100%;
          // @include flex();
          display: flex;
          height: auto;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: $colorZ;
          // padding: 0 15px;
          margin-top: 5px;
          .item-l span {
            border-radius: 8px;
            // display: inline-block;
            margin-bottom: 0;
            border-color: #333;
            color: #333;
            font-weight: 500;
            margin: 4px 6px;
            height: 24px;
            line-height: 22px;
            border: 1px solid #dedede;
            background-color: #fdfdfd;
            width: 40px;
            text-align: center;
            cursor: pointer;
          }

          //  按钮
          .item-btn {
            // width: 100px;
            // height: 30px;
            // background-color: #1e9133;
            font-size: 14px;
            color: $colorZ;
            border: 1px solid $colorZ;
            padding: 5px;
            border-radius: 5px;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
              background-color: $colorZ;
              color: #fff;
            }
          }
        }
      }
    }

    // 关于我们
    .info-con {
      width: 100%;
      height: 400px;
      // background: pink;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 400px;
        height: 400px;
      }
      .info-box {
        width: 500px;
        height: 70%;
        background: $colorZ;
        padding: 10px;
        box-sizing: border-box;
        p {
          color: #fff;
          text-indent: 20px;
          font-size: 14px;
          margin: 15px auto;
          line-height: 49px;
        }
      }
    }
  }
}
.my-body {
  font-size: 17px;
  width: 327px;
  height: 600px;
  line-height: 76px;
  text-align: left;
  margin: 20px auto;
  overflow: auto;
  scrollbar-face-color: #63dbc1;
}
.my-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
</style>
