import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/home.vue'
import Index from './pages/index.vue'
import Sound from './pages/sound.vue'
import Login from './pages/login.vue'
import Register from './pages/register.vue'
import Agreement from './pages/agreement.vue'
import Jf from './pages/jf.vue'
import Ac from './pages/ac.vue'
import Zw from './pages/zw.vue'
import Zw2 from './pages/zw2.vue'
import Zw3 from './pages/zw3.vue'


Vue.use(Router)

export default new Router({
    routes: [
        // 根路由
        {
            path: '/',
            name: 'home',
            component: Home,
            redirect: '/index',

            children: [
                // 首页
                {
                    path: '/index',
                    name: 'idnex',
                    component: Index,

                },
                // 详情页
                {
                    path: '/sound/:id',
                    name: 'sound',
                    component: Sound,

                },
                // 登录
                {
                    path: '/login',
                    name: 'login',
                    component: Login,
                },
                // 注册
                {
                    path: '/register',
                    name: 'register',
                    component: Register,
                },


                {
                    path: '/agreement',
                    name: 'agreement',
                    component: Agreement,
                },
                {
                    path: '/jf',
                    name: 'jf',
                    component: Jf,
                },
                {
                    path: '/ac',
                    name: 'ac',
                    component: Ac,
                },
                {
                    path: '/zw',
                    name: 'zw',
                    component: Zw,
                },
                {
                    path: '/zw2',
                    name: 'zw2',
                    component: Zw2,
                },
                {
                    path: '/zw3',
                    name: 'zw3',
                    component: Zw3,
                }


            ]
        },
    ]
})