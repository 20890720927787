<template>
  <div>
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <!-- 服务协议 -->
      <div class="ag-con">
        <h3>北京卓训世纪文化传播有限公司</h3>
        <div class="t-con">
          <p>
            北京卓训世纪文化传播有限公司下称“卓训世纪”）请您仔细阅读（未成年人应在其法定监护人的陪同下阅读）本《卓训世纪用户协议》（下称“本协议”）中的各个条款，并请确认您同意上述内容，您同意本协议将适用于您使用本网站及借助本网站实施的任何活动。
          </p>
          <p>本协议的最终修订时间为2020年12月23日星期三。 名词解释</p>
          <p>“账号”指的是您在本网站开设并使用的账号。</p>
          <p>
            “客户”“您”“音乐人”指的是访问或使用本网站的自然人、法人或其他组织。
          </p>
          <p>“卖方”指的是通过本网站提供服务或产品的用户。</p>
          <p>
            “买方”指的是希望或已经通过本网站从卖方手中购买卖方服务或产品的用户。
          </p>

          <p>“项目”指的是由买方通过卓训世纪网站提供给卖方的工作项目。</p>
          <p>“项目包”指的是由卓训世纪根据买方的需求而设计并展示的项目内容。</p>
          <p>“下单”指的是买方通过本网站提交项目需求。</p>
          <p>
            “平台服务费”指的是买方通过本网站提交项目需求时需向卓训世纪支付的费用。
          </p>
          <p>
            “项目分成费”指的是卖方通过本网站获得项目时需向卓训世纪支付的一定比例的项目费用。
          </p>
          <p>
            “托管支付”指的是买方将项目所涉款项打入卓训世纪指定账户后，卓训世纪依据双方的指示将相关款项支付予卖方的行为。
          </p>
          <p>
            “知识产权”指的是在世界范围内存在的部分或所有知识产权以及上述权利指涉的客体，包括：（1）专利、著作权、登记或未登记的商标以及其他对保密信息进行保密的权利；以及（2）就（1）项下内容进行登记申请的权利，不论上述内容是否已发表、已经登记或能够获得登记或者已在任何法律体系下实际存在。
          </p>
          <p>
            “用户合同”指的是（1）本用户协议；（2）本网站合理修改的行为准则；（3）卖方和买方都接受并上传到本网站的其他任何约定条款，违反用户协议和行为准则的除外。以及（4）项目中所包含的，且经本网站同意的条款，违反用户协议和行为准则的除外。
          </p>
        </div>
      </div>

      <!-- 网站概述 -->
      <div class="ag-con">
        <h3>网站概述</h3>
        <div class="t-con">
          <p>
            本网站有权根据需要不时地制订、修改本协议及各类规则，并以网站公示的方式进行公告。本网站是一个为用户提供交易音乐服务的平台，用户可以通过线上平台完成项目交易、购买和出售产品以及使用我们提供的其他相关服务。买方和卖方要使用前述服务必须在网站上注册帐户。
          </p>
          <p>
            本网站用户必须是具有完全民事行为能力的自然人，或者是具有合法经营资格的实体组织，并就所提供的服务内容和范围具备法律、法规、行业规章规定的相应资质。无民事行为能力、限制民事行为能力人以及无经营或特定经营资格的组织不得注册为本网站用户或超过其民事权利或行为能力范围在本网站进行交易；否则，本网站有权采取取消订单、冻结或关闭账户、拒绝提供服务等措施。行为人因不具备行为能力或缺少相应资质给任何第三方造成损失的，本网站不承担责任；行为人给本网站及相关方造成损失的，还应承担赔偿责任。本网站只接受与身份证相符的真实姓名或与企业营业执照相符的法定代表人姓名进行注册，注册人（自然人）对通过该账户实施的任何行为承担全部责任。本网站保留拒绝特定个人或实体在本网站注册的权利。
          </p>
        </div>
      </div>

      <!-- 注销账户 -->
      <div class="ag-con">
        <h3>注销账户</h3>
        <div class="t-con">
          <p>
            在同时满足以下条件的前提下，发送邮件至 zhuyanyun@inke.cn
            ，您可以注销您的账户：
          </p>
          <p>（1）没有任何通过本网站对接且未完成的项目；以及</p>
          <p>（2）没有任何未解决事宜（如您的账户被冻结或限制）；以及</p>
          <p>（3）没有任何未支付的价款；以及</p>
          <p>（4）没有其他本网站认为可能存在的安全、法律隐患。</p>
        </div>
      </div>

      <!-- 账户安全 -->
      <div class="ag-con">
        <h3>账户安全</h3>
        <div class="t-con">
          <p>
            若您发现或知道任何破坏或威胁网站安全的行为，您应当在第一时间通知我们并尽可能采取一切措施降低上述侵害（包括保存证据和通知有权机关）。您对您账户的密码负担安全保护责任。若您未能采取合理措施保护您的账户安全，由此产生的一切损失有您自己承担。
          </p>
        </div>
      </div>

      <!-- 网站使用 -->
      <div class="ag-con">
        <h3>网站使用</h3>
        <div class="t-con">
          <p>当使用本网站时，您承诺不实施以下行为：</p>

          <p>（1）在本网站中不恰当的类别或板块下发布内容；</p>

          <p>
            （2）任何违反任何法律法规规定、或本网站规定、或侵犯第三方合法权利的行为；
          </p>

          <p>
            （3）除非卖方未合理履行提供涉及服务的义务，拒绝或不合理地拖延为卖方所提供的服务支付相应价款的行为；
          </p>

          <p>
            （4）规避或操纵本网站的收费结构、计费程序或欠逃对本网站的应付价款；
          </p>

          <p>
            （5）发布错误的、不准确的、误导性的、诽谤性的或攻击性内容（包括个人信息）；
          </p>

          <p>
            （6）采取任何可能破坏网站反馈或评价系统的行为（如将网站反馈或评价信息导出、展示或用于本网站目的之外的其他用途）；
          </p>

          <p>（7）以任何形式擅自转让或授权他人使用自己在本网站的账户；</p>

          <p>（8）在本网站发布各类违法或违规信息；</p>

          <p>
            （9）以干预或操纵物品价格等不正当竞争方式扰乱网上交易秩序，从事与网上交易无关的不当行为，在交易平台上发布任何违法信息；
          </p>

          <p>
            （10）采取不正当手段（包括但不限于虚假交易、互换好评等方式）提高自身或他人信用度，或采用不正当手段恶意评价其他用户，降低其他用户信用度；
          </p>

          <p>
            （11）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序资料，加以上载、发布、发送电子邮件或以其他方式传送，干扰或破坏本网站和服务或与本网站和服务相连的服务器和网络；
          </p>

          <p>
            （12）未经本网站事先书面授权，“架构”“模仿”或将本网站的任何部分内容展示或用于第三方网站；
          </p>

          <p>
            （13）未经本网站书面允许，将本网站资料以及在交易平台上所展示的任何信息以复制、修改、翻译等形式制作衍生作品、传播或公开展示或对和本网站相关的任何软件实施逆向工程；
          </p>

          <p>
            （14）侵犯或者擅自使用任何来自本网站的权利、内容或本网站享有知识产权的标志等；
          </p>

          <p>
            （15）未经用户同意，收集、散播在服务过程中收集的用户的受保密义务保护的信息。
          </p>

          <p>
            为避免可能引起的争议，卓训世纪对用户的每一步操作都将进行记录并制成操作日志，该日志仅用于内部存档供日后双方引起争议后进行查阅，不用于任何商业或除前述之外的其他用途。
          </p>
        </div>
      </div>

      <!-- 访问及干扰 -->
      <div class="ag-con">
        <h3>访问及干扰</h3>
        <div class="t-con">
          <p>
            您同意，未经本网站明确书面许可，不得出于任何目的使用任何机器人或其他自动化手段访问本网站。此外，您同意您不会实施以下行为：
          </p>
          <p>
            （1）实施任何行为使得或可能使得（由本网站依据合理的审查认定）本网站设施承受不合理或不合比例的超大负荷；
          </p>
          <p>
            （2）干扰、破坏、操控、扰乱、修改、损坏任何设备、软件系统或任何连接或使用本网站或您账户的网络，或帮助他人实施上述行为；
          </p>

          <p>
            （3）未经网站或其他有权主体的事先书面同意，基于本网站上公布的任何内容（除您的个人信息外）复制、修改或制作演绎作品及其他侵犯有权主体权益的行为；
          </p>

          <p>（4）干扰或企图干扰网络、网络服务或网络工具的正常运作；</p>

          <p>
            （5）绕过本网站的机器人设置或其他本网站用来防止或限制对本网站访问的措施。
          </p>
        </div>
      </div>

      <!-- 网站内容 -->
      <div class="ag-con">
        <h3>网站内容</h3>
        <div class="t-con">
          <p>
            您完全了解并同意，当您将内容（包括但不限于提交的展示作品、向客户提交的项目作品等）交予本网站时，你将授予本网站对于内容中您所享有的部分或全部著作权、商标权等权利，上述权利为非独占、在世界范围内适用的、无期限的、不可撤销的、免费的、可进行分许可的（包括不限次的分许可），且对于任何目前已有的或将来可能产生的媒体适用。
          </p>
        </div>
      </div>

      <!-- 您完全了解并同意 -->
      <div class="ag-con">
        <h3>您完全了解并同意:</h3>
        <div class="t-con">
          <p>
            （1）
            本网站仅仅是为用户内容提供线上传播和发布平台的门户网站。我们不保证您能够通过本网站获取特定的用户内容。我们有权利（但不负有义务）针对您提供的内容采取我们认为恰当的任何措施；
          </p>

          <p>
            （2）
            我们对于内容的删除、无法储存内容或无法提供内容等情形不承担任何的责任；以及
          </p>

          <p>
            （3）
            您所提交的部分或全部内容都可能由于未获得本网站的同意而无法在本网站发布，但本网站不具有审查所有内容的义务。本网站有权自行决定对用户内容进行同意、修改或驳回；
          </p>
          <p>
            （4）
            因不可归责于本网站的原因（如第三人恶意攻击、黑客攻击、第三人侵犯知识产权等）给您造成损失的，本网站不承担责任。
          </p>

          <p>
            （5）
            我们有权利随时对您交予本网站的内容进行授权价格、促销方式、授权方式、结算方式、结算周期的条款进行调整且无需事先通知您。
          </p>
        </div>
      </div>

      <!-- 您承诺您所提供的内容： -->
      <div class="ag-con">
        <h3>您承诺您所提供的内容:</h3>
        <div class="t-con">
          <p>（1）符合您与相对方约定的标准、质量、时间；</p>

          <p>
            （2）不会侵犯第三方的著作权、专利权、商标、商业秘密或其他知识产权或所有权、名誉权等其他合法权利；
          </p>

          <p>（3）不会违反任何法律或法规；</p>

          <p>（4）不包含淫秽色情内容；</p>

          <p>（5）与恐怖活动无关；</p>

          <p>（6）不包含关于用户或第三人的错误或不准确的信息；以及</p>

          <p>
            （7）不包含任何意在毁坏、有意干扰、秘密截取或侵占获取任何数据或个人信息的病毒、代码或其他电脑程序。
          </p>

          <p>
            您知道并同意本网站可以将您的个人信息披露给相关公司，且您的个人信息将可能披露给中国人民共和国境外的第三人。如果您不同意此条款或希望撤回同意，您知道并同意我们将可能无法通过本网站为您提供服务并可能关闭您的帐户，同意该用户协议意味着您对该条款的知道与同意。
          </p>

          <p>
            本网站可能包含法律、金融、健康等信息。上述信息不是、也不应当被认为是本网站提供的意见或建议。您也不得将上述信息视作相应领域的专业意见。如果您有任何涉及某些专业领域的具体问题，您应当咨询该领域的专业人员。
          </p>

          <p>
            本网站所呈现的信息中包含第三方提供的内容，包括但不限于用户反馈和包含某些内容或意见及其原始文章（或上述第三方内容的链接）。本网站仅为呈现上述内容的平台和门户网站，且不对任何基于或关于上述第三方内容的内容承担任何责任，包括但不限于违反知识产权及其相关法律、侮辱诽谤、侵犯隐私、涉及淫秽色情或其他任何法律领域所可能包含的责任。
          </p>

          <p>
            本网站可能包含连接到第三方网站的链接。我们对上述第三方网站不具有任何的控制权；且我们不支持上述第三方网站中的内容、产品、服务、行为、规定或运作。使用上述来自第三方网站的内容或该第三方网站的链接所产生的所有风险都由使用者自身承担。
          </p>

          <p>
            本网站可以将您公司或商业名称、logo、图案或其他媒体及公众对您项目和帐户的描述用于本网站服务和／或其他与本网站相关的市场营销资料中，除非您提前明确向我们提出拒绝上述形式的请求且我们接受了该请求。为避免双方有可能产生的争议，本网站对各阶段上传的作品进行保存，该保存的文件仅用于内部存档供日后双方引起争议后进行查阅，不用于任何商业或除前述之外的其他用途。
          </p>
        </div>
      </div>

      <!-- 网站广告 -->
      <div class="ag-con">
        <h3>网站广告</h3>
        <div class="t-con">
          <p>
            除非您提前与我们达成协议，您不得在本网站为第三方网站、产品或服务发布广告信息。任何在本网站发布的网页地址链接必须与项目、产品、用户或通过本网站提供的服务相关。
          </p>
          <p>
            本网站可以在网站平台上展示赞助商的广告和促销信息。您知道并同意本网站不对基于上述广告／促销信息或您与上述赞助商进行交易而导致的任何损失承担责任。此外，您了解并同意赞助商的广告和促销信息的内容是受到著作权、商标、专利或其他权利法律保护的。除非获得我们或第三方权利主体的明确授权，您同意不对基于上述广告／促销信息的内容进行修改、出售、传播、持有或制作演绎作品。
          </p>
        </div>
      </div>

      <!-- 反馈 -->
      <div class="ag-con">
        <h3>反馈</h3>
        <div class="t-con">
          <p>
            您知道并同意将通过本网站发布的反馈意见、评论或其他任何构成评价性意见或排名信息的著作权转让予本网站。您知道以上信息为本网站所独有，尽管本网站允许您在作为网站用户的期间使用上述信息。禁止在未经本网站事先同意的情况下，将上述信息以任何违反本网站规定的形式使用或进行交易。
          </p>
          <p>
            您不能故意或过失地进行任何使得或可能破坏本网站反馈系统的行为。若发现上述行为，本网站有权决定对您的帐户采取暂停或取消等的措施。
          </p>
          <p>
            本网站的反馈系统及反馈系统上的信息属于本网站所有，其不得被用于本网站运行之外的其他任何目的（包括但不限于推广或披露任何关于您的综合评级或反馈意见）。未经本网站的书面许可，您不得在除本网站之外的任何真实或虚拟平台使用买方或卖方的反馈，除非该平台由卓训世纪运作。
          </p>
        </div>
      </div>

      <!-- 隐私条款 -->
      <div class="ag-con">
        <h3>隐私条款</h3>
        <div class="t-con">
          <p>
            卓训世纪制定本隐私条款是为了表明我们一直致力于保护用户的隐私信息。以下内容包括本网站对信息收集和传播行为的说明。若您使用本网站服务并接受用户协议，意味着您也接受本隐私条款的内容。如果您不接受本隐私条款的内容，我们将无法为您提供服务。下列内容中所涉及的“我们”、“卓训世纪”指的是北京火星火翎文化科技有限公司。
          </p>
          <p>您知道并同意我们在您注册或访问卓训世纪网站时收集您的以下信息：</p>
          <p>
            （1）为了向您提供卓训世纪网站、服务、工具以及在本条款中声明的其他目的，我们可能收集以下种类的个人信息：姓名和联系信息，如移动电话号码、电子邮箱、地址，身份信息，如身份证号、身份证照片、企业资质照片和（依据所提供服务的需要）财务信息，如银行卡帐号；基于您在本网站活动的交易信息、账单和其他您为了购买商品而提供的信息；当我们认为您存在或可能存在违反网站条款的行为时，我们要求您提供的用以验证您个人身份的其他个人信息；您与本网站互动的信息，包括但不限于设备ID、设备型号、定位信息、电脑和链接信息、网页视图数据、IP地址和标准网页记录信息。
          </p>

          <p>
            （2）
            一般信息：卓训世纪会出于改善我们的网站和服务的目的，收集不具有身份识别性的、概括性及一般性的信息。
          </p>

          <p>
            （3）
            非个人信息：卓训世纪会在您访问卓训世纪的时候收集非个人信息。当您访问网站时，卓训世纪和第三方服务提供商（如谷歌浏览器）可能通过您的浏览器或移动设备，包括但不限于您的IP地址中的cookies、服务器日志和其他同类技术设备收集和记录您的非个人信息。
          </p>

          <p>
            （4）
            我们可能会将我们所搜集的非个人信息与个人信息结合。如果我们实施上述行为，且当结合所得的信息可能被用于与个人信息同样的用途时，即个人身份识别或定位，上述结合获得的信息将被视为个人信息。
          </p>
        </div>
      </div>

      <!-- 您知道并同意我们储存并使用您的信息 -->
      <div class="ag-con">
        <h3>您知道并同意我们储存并使用您的信息：</h3>
        <div class="t-con">
          <p>
            （1）您的信息将被保证处于卓训世纪的控制范围之内。我们通过技术和安全管理措施来保护您的信息，以减少您信息遭受丢失、不当使用、未授权获取、披露和改动的风险。我们所使用的保护措施包括防火墙、数据加密、对我们数据中心的物理访问控制以及信息访问的授权控制。
          </p>

          <p>
            （2）当您使用卓训世纪网站时，我们可能需要您提供特定的信息。除非本条款另外规定，卓训世纪不会将具有身份识别性的信息或交易信息与任何个人或实体共享。除了与您进行交易并共享信息的主体外，没有任何第三方可以通过卓训世纪获取您具有身份识别性和交易信息。我们所搜集的信息将用于改进我们网站的内容、向用户及时告知我们网站的更新情况以及与用户的沟通，如用户服务。
          </p>

          <p>
            （3）市场营销：您同意我们可能将您的个人信息用于向您发送关于我们服务和工具的信息、依据您的个人偏好挑选的有针对性的市场营销和促销活动信息。在未获得您同意的情况下，我们不会基于市场营销的目的将您的个人信息与非关联性公司或第三人共享。我们可能出于市场营销的目的使用或共享非个人信息。
          </p>

          <p>
            （4）如非出于可归责于卓训世纪的原因，第三方使用任何手段非法获取您的信息给您造成损失的，卓训世纪不就该损失承担责任。
          </p>
        </div>
      </div>

      <!-- 您知道并同意我们共享您的信息 -->
      <div class="ag-con">
        <h3>您知道并同意我们共享您的信息：</h3>
        <div class="t-con">
          <p>
            （1）我们将依据法律规定、卓训世纪条款规定、回应本网站内容侵犯第三方权利控诉的需要、保护第三人权利、财产和安全的需要披露所收集的个人信息。上述信息将依据法律法规的规定进行披露。如前所述，未经您的明确许可，我们不会基于第三人的市场营销目的向其披露您的信息。
          </p>

          <p>
            （2）
            我们可能会出于前述目的，将您的个人信息披露给我们在海外的关联企业和第三方供应商和服务提供商。我们采取合理措施保证前述的海外信息接收主体不会违反关于保护您个人隐私的义务。
          </p>
          <p>
            （3）
            我们可能会与广告商或其他第三人共享一般信息。卓训世纪采用行业通用标准的加密技术保护通过网站传送和接收的用户数据和交易数据。
          </p>
        </div>
      </div>

      <!-- 您可以访问并更新您的信息 -->
      <div class="ag-con">
        <h3>您可以访问并更新您的信息:</h3>
        <div class="t-con">
          <p>
            （1）如果您认为我们对您个人信息的记录有错误、不完整或不准确，您可以要求我们进行更正。
          </p>
          <p>
            （2）您可以通过发送电子邮件至我们的邮箱support@Sparkmusic.com请求获取我们对您个人信息的记录。若您有权获取上述信息，我们将尽力通过恰当的途径为您提供（例如通过邮寄或发送电子邮件的方式发送给您）。我们可能会就此向您收取一定的管理和邮寄等其他合理费用。我们不会就您单纯的请求行为和更正您个人信息的行为收取任何的费用。
          </p>

          <p>
            （3）在某些情况下，我们可能无法为您提供我们所获取的个人信息。比方说，如果上述个人信息的披露可能侵犯他人的隐私或导致保密义务的违反。在前述情形下，我们将在拒绝提供相应信息的回复中阐明无法提供的理由。
          </p>

          <p>
            我们可能随时对本隐私条款进行更改。最新版本的隐私条款会及时公布在我们的网站上，请您定期查看。隐私条款的变更从公布之日起生效。
          </p>
        </div>
      </div>

      <!-- 知识产权侵权 -->
      <div class="ag-con">
        <h3>知识产权侵权</h3>
        <div class="t-con">
          <p>
            本网站依据相关法律、法规将对明确的举报知识产权侵权通知作出相应反应措施。本网站所制定的知识产权侵权条款将对相关行为进行规制，使得本网站能够更直接地获取举报存在侵权行为的通知，并减少欺诈或表述模糊等情况。本网站用户不能侵犯包括他人的著作权在内的知识产权以及其他权利。一旦由于用户上传或发布的内容发生权利纠纷或侵犯了任何第三方的合法权益，其责任由用户本人承担，因此给卓训世纪或任何第三方造成损失的，用户应负责全额赔偿。所有因用户上传或发布的内容而引起的知识产权纠纷，与卓训世纪无关。如果您确信您的知识产权权利受到侵害，请通过发送邮件至support@Sparkmusic.com及时通知本网站，我们将尽一切合理义务进行审查。由于您的误报给任何第三方造成的损失，由您承担相关责任。
          </p>
        </div>
      </div>

      <!-- 托管支付 -->
      <div class="ag-con">
        <h3>托管支付</h3>
        <div class="t-con">
          <p>
            为保障您的交易安全，卓训世纪及为卓训世纪提供金融服务的第三方为您提供资金托管及由此产生的、另行约定的监管和通知服务。
          </p>
        </div>
      </div>

      <!-- 费用和服务 -->
      <div class="ag-con">
        <h3>费用和服务</h3>
        <div class="t-con">
          <p>
            本网站对所提供的特定服务向卖方及买方收取一定费用，包括但不限于项目分成费、平台服务费、声音设计费。您可以通过产品介绍页或常见问题页来查看费用细则，费用细则将根据需要进行修改，并以网站公示的方式进行公告。本网站可以基于促销或推广活动，对上述表格中的费用进行临时性变更，且上述价格的变更自本网站以公示方式展示相关信息时立即生效。
          </p>
        </div>
      </div>

      <!-- 税费 -->
      <div class="ag-con">
        <h3>税费</h3>
        <div class="t-con">
          <p>
            您应当自行承担使用本网站而产生的项目相关税费，包括任何依据服务提供地法域法律而需要缴纳的与货物和服务相关的税款或增值税，上述税费将在寄送给您的费用账单上列明。与音乐制作服务费用相关的发票根据买方与卖方另行签订的设计协议由提供设计服务一方开具。
          </p>

          <p>
            卓训世纪作为一家在北京注册成立的公司，我们所收取的任何收入都将依据中华人民共和国（不含香港、澳门、台湾地区）的法律缴纳增值税，这将在相应的发票上得到体现。与平台服务费、项目发布费、声音设计费相关的发票根据您与卓训世纪的具体约定由卓训世纪开具。您也应当履行由您所在法域法律所规定的个人所得税纳税义务。
          </p>
        </div>
      </div>

      <!-- 货币 -->
      <div class="ag-con">
        <h3>货币</h3>
        <div class="t-con">
          <p>
            本网站的所有支付的货币单位皆为人民币。本网站不提供任何货币兑换标准。
          </p>
        </div>
      </div>

      <!-- 用户间项目实施 -->
      <div class="ag-con">
        <h3>用户间项目实施</h3>
        <div class="t-con">
          <p>
            当买方在网站发布项目，且卖方通过网站承揽了上述项目，则应视为买方和卖方的该项合同已成立，该合同约定买方同意购买、且卖方同意提供相应的服务。您同意您不得签订其他含有任何与上述合同条款相冲突内容的合同或其他具有法律效力的约定。
          </p>

          <p>
            您以个人的身份独立对其他用户承担义务并对该义务的履行承担责任。如果您未履行相应的义务，您可能会对该用户承担相应的责任。您应当确认您了解那些与您在本网站实施行为相关的国内法、国际法等法律法规与行业规章。
          </p>

          <p>
            如果有用户未履行对您所负有的任何义务，您有权以个人的身份对该用户主张您所享有的任何权利。为免生疑问，本网站无义务基于买卖双方订立的合同向其中的任一方主张任何权利。
          </p>

          <p>
            卖方和买方依据其各自所在领域的法律，可能享有无法依据约定而排除的法定权利。本用户协议的约定若与上述法定权利产生冲突，则以相应法律的规定为准。
          </p>

          <p>
            每一个用户都完全了解并同意，买方和卖方之间的法律关系为承揽合同法律关系。本用户协议不会导致上述主体之间形成合伙、合资企业、代理或劳动法律关系。本用户协议也不会导致用户和本网站之间形成合资企业、合伙、代理或劳动法律关系。
          </p>
        </div>
      </div>

      <!-- 用户间纠纷 -->
      <div class="ag-con">
        <h3>用户间纠纷</h3>
        <div class="t-con">
          <p>
            您了解并同意当您和其他用户之间就任何项目发生争议时，您应当首先尝试以公平、合理、符合法律规定和合同约定的方式解决任何就您和其他用户对该项目无法达成一致的问题，如依据项目而提供的服务的质量问题。对于与上述纠纷有关的事项而带来的各种损失以及法律责任，您同意赔偿并使我们（以及我们的员工、经理、代理人、分公司、合资企业和其他雇员）在该纠纷中产生的所有类型的诉请和赔偿免责。
          </p>
          <p>
            如果您与其他用户之间存在纠纷，您同意豁免我们（以及我们的员工、经理、代理人、分公司、合资企业和其他雇员）对从该纠纷中产生的所有类型的诉请和赔偿的全部责任。
          </p>
        </div>
      </div>

      <!-- 网站审查权利 -->
      <div class="ag-con">
        <h3>网站审查权利</h3>
        <div class="t-con">
          <p>
            本网站出于调查欺诈行为和风险管理等相关目的，有权查阅发布在本网站的通信内容并下载、获取或测试（若需要的话）所有与您使用本网站行为相关而上传的文件、程序和网页等。
          </p>
        </div>
      </div>

      <!-- 拒绝提供服务的权利 -->
      <div class="ag-con">
        <h3>拒绝提供服务的权利</h3>
        <div class="t-con">
          <p>
            本网站可能基于以下原因拒绝为您提供服务，即取消、暂停或限制您登陆您的账户：
          </p>
          <p>（1）若本网站认为您存在违反本用户协议的行为；</p>

          <p>（2）如果您以私下协商的方式恶意降低项目价格来逃避本网站收费；</p>

          <p>
            （3）若本网站认为您侵犯或可能侵犯他人的合法权利，包括但不限于侵犯他人的知识产权；
          </p>

          <p>（4）若本网站认为您曾经实施或正在实施欺诈或非法活动；</p>

          <p>（5）对本网站、用户或第三方的潜在损失进行风险管理；</p>

          <p>（6）其他类似的原因。</p>

          <p>
            若本网站由于您违反本用户协议而关闭您的账户，您还应当就本用户协议项下规定的内容支付相应费用。若您违反本用户协议，您应当在支付您对本网站所有欠款的同时赔偿本网站调查您违约行为以及追讨上述欠款的所有损失及合理支出（包括律师费）。上述赔偿不影响本网站要求您承担基于该协议及其他法律规定下的违约责任。
          </p>
        </div>
      </div>

      <!-- 拒绝提供服务的权利 -->
      <div class="ag-con">
        <h3>非保险或担保</h3>
        <div class="t-con">
          <p>
            我们不提供任何形式的保险，或其他类似的用户保护。本协议中的所有条款不被认为是本网站对您进行的担保。
          </p>
        </div>
      </div>

      <!-- 不担保网站内容 -->
      <div class="ag-con">
        <h3>不担保网站内容</h3>
        <div class="t-con">
          <p>
            本网站是一个动态的即时网站，因此本网站上的内容会被频繁地更改；同时，也可能存在一些我们不经意或第三方不经意或故意发布的，带有伤害性、侵犯性、错误性、误导性、欺骗性的信息。本网站所有的内容都是不作修理或改进而按照其原有形态呈现的、我们并不保证上述内容不存在明显的或潜在的瑕疵。我们特别声明不对任何的所有权、产品／服务的适销性、针对特定用途的适用性、质量、非侵权性提供担保。
          </p>
        </div>
      </div>

      <!-- 网站责任限免 -->
      <div class="ag-con">
        <h3>网站责任限免</h3>
        <div class="t-con">
          <p>
            在任何情况下，我们、我们的相关实体、附属主体或员工都不对以下情形承担任何合同、侵权或其他任何形式的法律责任：
          </p>

          <p>
            （1）您遭受的任何不可归责于本网站的直接或间接、特定或非特定、附带或结果性损害；
          </p>

          <p>（2）您所遭受的任何直接或间接的收入、商业或利润损失；</p>

          <p>（3）您由于进行本网站交易所遭受的任何损害或损失。</p>

          <p>
            （4）您由于违反本用户协议或国家强制性法律法规规定给任何第三方造成的任何损害或损失。
          </p>

          <p>
            不论我们、我们的相关主体、附属主体或员工是否已被告知上述损失或损害发生可能性，上述责任的限免也不受影响。若您所在法域的法律不允许上述约定的某些责任排除或例外性规定，则以该法律的规定为准。在这种情况下，我们的责任豁免程度为在法律所允许的范围内的最大限度。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "北京卓训世纪科技有限公司";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  .ag-con {
    margin: 30px auto;
    // 文字容器
    .t-con {
      margin: 30px auto;
      font-size: 14px;
      line-height: 40px;
      text-align: left;
      p {
        margin: 10px auto;
      }
    }
  }
}
</style>
