<template>
  <div class="ac1">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <!-- 资讯区块3 -->
      <div class="s3">
        <!-- 左侧 -->
        <div class="s3-1">
          <!-- 标题 -->
          <div class="s3-title">行业资讯</div>
          <!-- 列表内容 -->
          <div class="s3-con">
            <ul>
              <!-- 循环项 -->
              <li
                class="s3-item"
                v-for="(item, index) in ac4"
                :key="index"
                @click="gopay()"
              >
                <!-- 图片 -->
                <div class="s3-img">
                  <img :src="item.img" alt="" />
                </div>
                <!-- 信息 -->
                <div class="s3-info">
                  <!-- 标题 -->
                  <div class="s3-t">
                    {{ item.title }}
                  </div>
                  <!-- 信息层 -->
                  <div class="s3-user">
                    <!-- 时间 -->
                    <span class="s3-time">
                      <i></i>
                      {{ item.time }}
                    </span>
                    <!-- 作者 -->
                    <span class="s3-author">
                      <i></i>
                      {{ item.author }}
                    </span>
                    <!-- 收费 -->
                    <!-- <span class="s3-pay">
                      {{ item.pay }}
                    </span> -->
                  </div>

                  <!-- 介绍 -->
                  <div class="s3-brier">
                    <p>{{ item.article1 }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="请选择支付方式"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
      </template>
    </modal>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
import Modal from "./../components/Modal";
export default {
  name: "ac1",
  components: {
    NavHeader,
    Modal,
  },
  data() {
    return {
      href: "index.html",
      ac4: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "SparkMusic音乐授权平台";
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.ac4 = data;
      });
    },

    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },

    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
      }
      // 未登录
      if (this.state !== 0) {
        alert("请登录付费2元后可浏览！！");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  background-color: #f0f0f0;
  .container {
    // 资讯区块3
    // 资讯区块3
    .s3 {
      width: 100%;
      // height: 2000px;
      margin: 80px auto;
      // background-color: pink;
      display: flex;
      justify-content: space-between;
      // 左侧
      .s3-1 {
        width: 100%;
        height: 100%;
        // background-color: #ff6600;
        margin-right: 40px;
        // 标题
        .s3-title {
          width: 100%;
          height: 60px;
          background-color: #fff;
          font-size: 18px;
          font-weight: bold;
          color: #121212;
          line-height: 60px;
          padding-left: 20px;
          box-sizing: border-box;
          border-left: 6px solid #63dbc1;
        }

        // 下层容器
        .s3-con {
          width: 100%;
          margin: 10px auto;
          ul {
            width: 100%;
            // 循环项
            .s3-item {
              width: 100%;
              height: 200px;
              background-color: #eee;
              margin: 25px auto;
              display: flex;
              align-items: center;
              // 左侧
              .s3-img {
                width: 200px;
                height: 200px;
                background-color: red;
                margin-right: 30px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              // 右侧
              .s3-info {
                width: 650px;
                height: 100%;
                // background-color: #c60023;
                padding: 20px;
                box-sizing: border-box;
                // 标题
                .s3-t {
                  font-size: 16px;
                  font-weight: bold;
                }
                // 时间 / 作者层
                .s3-user {
                  margin: 25px auto;
                  span {
                    font-size: 14px;
                    color: #121212;
                    margin: 0 15px;
                  }
                }
                // 简介
                .s3-brier {
                  font-size: 13px;
                  margin-top: 20px;
                  width: 500px;
                  // background-color: #fff;

                  p {
                    white-space: nowrap; //不换行
                    overflow: hidden; //超出隐藏
                    text-overflow: ellipsis; //变成...
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
