<template>
  <div class="sound">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div class="box">
      <!-- 定宽布局 -->
      <div class="container">
        <!-- 上层 -->
        <div class="s-t">
             <!-- 图片 -->
          <div class="s-img">
            <img src="/imgs/logo2.jpg" alt="" />
          </div>
          <!-- 信息 -->
          <div class="s-info">
            <!-- 歌曲名称 -->
            <div class="s-title">{{ this.title }}</div>
            <!-- 歌手 -->
            <!-- <div class="s-name">作曲:{{ this.name }}</div> -->
            <!-- 发行时间 -->
            <div class="s-time">发行时间:{{ this.time }}</div>
            <!-- 播放器 -->
            <div class="s-au">
              <vz>
                <a1 :msrc="this.src"></a1>
              </vz>
            </div>
          </div>
       
        </div>
        <!-- 下层 -->
        <div class="s-con">
          <p>{{ this.lrc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
import a1 from "./../components/Ma";
import vz from "./../components/vz";
export default {
  components: {
    a1,
    vz,
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
      // 获取 id
      myId: this.$route.query.id,
      // 歌名
      title: "",
      // 作者
      name: "",
      // 时间
      time: "",
      // 歌词
      lrc: "",
      // audio
      src: "",
      // 歌曲 数据
      msc1: [],
    };
  },
  // beforeMount() {
  //   this.init();
  // },
  mounted() {
    this.init();
    document.title = "博拉琴房";
  },
  methods: {
    init() {
      // console.log(data);
      this.axios.get("/mock/index.json").then((res) => {
        var vl = res.data;
        var data = vl.find((vl) => vl.id == this.myId);
        this.title = data.title;
        this.name = data.name;
        this.time = data.time;
        this.lrc = data.lrc;
        this.src = data.src;
        // this.src = data.src;
        // console.log(this.src);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.sound {
  .container {
    .s-t {
      width: 100%;
      height: 300px;
      // background-color: pink;
      margin-top: 60px;
      @include flex(center);
      border-bottom: 5px solid $colorZ;
      margin-bottom: 30px;
      // 左侧 信息
      .s-info {
        width: 30%;
        height: 100%;
        padding: 30px 0 0 30px;
        box-sizing: border-box;
        text-align: center;
        // 歌曲名称
        .s-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 30px;
        }
        // 歌手
        .s-name {
          font-size: 18px;
          margin-bottom: 20px;
        }
        // 发行时间
        .s-time {
          font-size: 18px;
          margin-bottom: 20px;
        }
        .s-au {
          width: 300px;
          // height: 60px;
          // background-color: #fff;
          margin: 0 auto;
        }
      }
      // 右侧图片
      .s-img {
        width: 70%;
        // margin-left: 30px;
        height: 100%;
        // background-color: #fff;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .s-con {
      background: url("/imgs/bj1.jpg") no-repeat center;
      p {
        width: 300px;
        line-height: 58px;
        font-size: 18px;
        margin: 20px auto;
      }
    }
  }
}
</style>
