<template>
  <div class="footer">
    <div class="my-container">
      <!-- logo区块 -->
      <div class="f-logo">
        <img src="/imgs/logo.png" alt="" />
        <h2>深圳市博尔姆斯科技有限公司</h2>
      </div>
      <!-- 信息区块 -->
      <div class="f-info">
        <h2>联系我们</h2>
        <ul>
          <li>{{ this.phone }}</li>
          <li>{{ this.email }}</li>
          <li>
            <a href="https://beian.miit.gov.cn/#/Integrated/index">{{
              this.icp
            }}</a>
          </li>
          <li>{{ this.addr }}</li>
        </ul>
      </div>

      <!-- 介绍区块 -->
      <div class="f-jj">
        <h2>关于我们</h2>
        <p>
          博尔姆斯（深圳）科技有限公司注册成立于2021年。是一家集音乐制作、线上教育、留学指导、数字传媒、全球夏令营为一体，建立B2B产业链的专业科技公司。公司位于深圳市。Promus（博尔姆斯）取自拉丁文，寓意：“专业管家”；秉承着“专业、深度、探索、服务”的经营理念，致力于打造为用户提供更精准、专业的品牌团队。

        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-footer",
  props: {
    email: String,
    phone: String,
    icp: String,
    name: String,
    addr: String,
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.footer {
  width: 100%;
  height: 250px;
  background-color: #555;
  // logo 层 居中
  .my-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // logo
    .f-logo {
      width: 300px;
      height: 120px;
      text-align: center;
      img {
        width: 210px;
        height: 80px;
      }
      h2 {
        color: #fff;
        // font-size: 14px;
        margin-top: 20px;
        text-align: center;
        font-size: 19px;
        font-weight: bold;
      }
    }
    // 信息
    .f-info {
      h2 {
        font-size: 21px;
        font-weight: bold;
        color: #fff;
      }
      ul {
        li {
          color: #fff;
          font-size: 14px;
          margin: 10px auto;
          a {
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
    // 简介
    .f-jj {
      color: #fff;
      width: 300px;
      h2 {
        font-size: 21px;
        font-weight: bold;
        color: #fff;
      }
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        font-size: 12px;
        // text-indent: 20px;
        line-height: 25px;
        margin-top: 20px;
        // white-space: nowrap;
      }
    }
  }
}
</style>
