<template>
  <div class="header" :class="{ is_fixed: isFixed }">
    <!-- 定宽 -->
    <div class="my-container">
      <!-- 左侧 logo 首页 -->
      <div class="n-l">
        <!-- logo -->
        <div class="n-logo">
          <a :href="href"><img src="/imgs/logo.png" alt="" /></a>
          <a :href="href">
            <!-- <h2>博拉琴房</h2> -->
          </a>
        </div>
        <div class="n-index"><a :href="href">首页</a></div>

        <div class="n-index">
          <a href="/#/jf">用户服务协议</a>
        </div>

        <div class="n-index">
          <a href="/#/zw2">未成年人保护机制</a>
        </div>

        <div class="n-index">
          <a href="/#/zw3">网站信息安全管理制度</a>
        </div>

        <div class="n-index">
          <a href="/#/zw"
            >自审制度</a
          >
        </div>

        <!-- <div class="n-index" @click="goAc()">
          <a href="javascript:;">行业资讯</a>
        </div> -->
      </div>
      <!-- 右侧 登录，注册 -->
      <div class="n-r">
        <!-- 登录注册 -->
        <span class="n-login">
          <span @click="goLogin()" v-show="this.LoggedShow">
            <i class="iconfont icon-denglu"></i> 登录</span
          >
          <span @click="goRegister()" v-show="this.LoggedShow">
            <i class="iconfont icon-zhuce"></i> 注册</span
          >
        </span>

        <!-- 用户名 -->
        <span
          class="n-info"
          v-show="!this.LoggedShow"
          v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></span>

        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
      // 是否吸顶
      isFixed: false,
    };
  },
  mounted() {
    // 绑定事件
    window.addEventListener("scroll", this.initHeight);
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },
    // 吸顶
    initHeight() {
      // 滚动高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      this.isFixed = scrollTop > 80 ? true : false;
    },
    // 跳转注册协议
    goAg() {
      this.$router.push("/agreement");
    },
    // 跳转纠纷
    goJf() {
      this.$router.push("/jf");
    },
    goAc() {
      this.$router.push("/ac");
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.initHeight, false);
  },
};
</script>
<style lang="scss" scope>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.header {
  width: 100%;
  // background-color: $colorZ;
  height: 80px;
  z-index: 999;
  box-shadow: 0px 15px 10px -15px #000;
  overflow: hidden;

  background-color: #fff;
  // 吸顶样式
  &.is_fixed {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .my-container {
    // background-color: pink;
    height: 100%;
    @include flex();

    // 左侧
    .n-l {
      display: flex;
      height: 100%;
      align-items: center;
      // logo
      .n-logo {
        width: 210px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
        }

        h2 {
          font-size: 21px;
          line-height: 80px;
        }
      }

      // 首页
      .n-index {
        a {
          font-size: 16px;
          color: $colorZ;
          margin-left: 30px;
        }
      }
    }
    // 右侧
    .n-r {
      font-size: 14px;
      color: $colorS;
      .n-login {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        min-width: 86px;
        padding: 0 4px;
        height: 30px;
        line-height: 35px;
        // background-color: #1e9133;
        border-radius: 8px;
        color: $colorZ;
        font-weight: 600;
        span {
          margin: 0 15px;
          &:hover i {
            color: #00c140;
          }
        }
        i {
          font-size: 16px;
          margin-right: 5px;
        }
      }

      .n-info {
        margin: 0 20px;
        color: $colorZ;
      }
    }
  }
}
</style>
